import {
  headBgColorList,
  primaryColorList,
  sidebarBgColorList,
  themePresetNum,
} from "../../themeConfig";
import { ThemeEnum } from "../enums/appEnum";
import { getAppEnvConfig } from "/@/utils/env";

export const prefixCls = "vben";

export default {
  prefixCls: prefixCls,
};

export const darkMode = ThemeEnum.LIGHT;
export const presetNum = themePresetNum;

// app theme preset color
export const APP_PRESET_COLOR_LIST: string[] = primaryColorList;

// header preset color
export const HEADER_PRESET_BG_COLOR_LIST: string[] = headBgColorList;

// sider preset color
export const SIDE_BAR_BG_COLOR_LIST: string[] = sidebarBgColorList;
